export const relationOptions = [
  {
    value: "患者本人",
    label: "患者本人",
  },
  {
    value: "照料者",
    label: "照料者",
  },
  {
    value: "患者和照料者",
    label: "患者和照料者",
  },
];
