




































































































































































































































import { Component, Vue } from "vue-property-decorator";
import ScaleComponent from "@/components/scale/index.vue";
import { relationOptions } from "@/assets/js/relations";
import { dosage } from "@/assets/js/dosage";
import { ScaleAnswer, ScaleAnswerMore } from "@/types";
import { Form } from "element-ui";
import {
  //新增量表接口
  ApiAddScaleRecord,
  //查询可选药品列表
  ApiGetMedicineList,
} from "@/apis";
import { scaleDesc } from "@/assets/js/scaleDesc";
@Component({
  components: {
    ScaleComponent,
  },
})
export default class AddScale extends Vue {
  //Data
  relationOptions = relationOptions;
  addMedicineDialog = false;
  multipleSelection = [];
  medicinePill = dosage;
  databaseId: any = "";
  //患者姓名和受教育程度
  patientInfo: any = {
    user_name: "",
    user_education: "",
  };
  form_data = {
    //是否需要获取患者填写量表前状态
    scaleStatusRadio: "",
    // 药物状态;
    medicineStatusRadio: "",
    // 植入设备状态;
    deviceStatusRadio: "",
    //评定者姓名
    assessName: "",
    // 评定时间;
    assessDate: "",
    //知情人与患者关系
    relationValue: "",
    //是否需要获取患者末次药量和时间
    lastMedicaineRadio: "",
  };
  // 末次服药时间;
  lastMedicineDate = "";
  //要查询的药物名称
  medicineName = "";
  //选择药物对话框表格数据
  medicineData: any = [];
  //所选择的药物列表：表格
  use_medicine_list = [];
  moduleId = "";
  scaleRadioArr = [] as Array<ScaleAnswer>;
  // ...More系列全部都是和103 104相关的;
  scaleRadioArrMore = [] as Array<ScaleAnswerMore>;
  rules = {
    scaleStatusRadio: [
      { required: true, message: "请选择是否需要", trigger: "blur" },
    ],
    medicineStatusRadio: [
      { required: true, message: "请选择药物状态", trigger: "blur" },
    ],
    deviceStatusRadio: [
      { required: true, message: "请选择植入设备状态", trigger: "blur" },
    ],
    assessName: [
      { required: true, message: "请输入评定者姓名", trigger: "blur" },
    ],
    assessDate: [
      { required: true, message: "请选择评定时间", trigger: "blur" },
    ],
    relationValue: [
      { required: true, message: "请选择知情人与患者关系", trigger: "blur" },
    ],
    lastMedicaineRadio: [
      {
        required: true,
        message: "请选择是否需要获取患者末次药量和时间",
        trigger: "blur",
      },
    ],
  };
  mengAnswer = [];

  created() {
    const userInfo = JSON.parse(localStorage.getItem("doctorLoginInfo") as any);
    this.form_data.assessName = userInfo.name;
    this.patientInfo = JSON.parse(
      sessionStorage.getItem("case_patientInfo") as string
    );
    this.moduleId = sessionStorage.getItem("case_moduleId") as string;
    this.databaseId = sessionStorage.getItem("case_databaseId");
  }
  mounted() {
    this.init();
  }
  //Methods
  init() {
    this.getMedicineList();
  }
  getMedicineList() {
    const params = {
      generic_name: this.medicineName,
    };
    ApiGetMedicineList(params)
      .then((res) => {
        if (res && res.success) {
          const data = res.data;
          this.medicineData = data.map((item: any) => {
            return {
              name: item.generic_name,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  is_test = false;
  //所服药物表格选择框选择变化时触发
  chooseCount(data: any) {
    const item: any = this.use_medicine_list.find((i) => {
      return i === data;
    });
    const index = this.getArrayIndex(this.use_medicine_list, item);
    this.use_medicine_list.splice(index, 1, data as never);
  }
  getArrayIndex(arr: any, obj: any) {
    let i = arr.length;
    while (i--) {
      if (arr[i] === obj) {
        return i;
      }
    }
    return -1;
  }
  // 判断这一项在数组中出现过几次;
  occurNumber(arr: any) {
    const newArr = [...new Set(arr.map((i: any) => i.sid))]; // 去重的时候需要注意和普通数组不同
    const list = [] as any;
    newArr.forEach((i) => {
      list.push(arr.filter((t: any) => t.sid === i));
    });
    const mlist = [] as any;
    list.forEach((i: any, index: number) => {
      mlist.push({
        name: newArr[index],
        num: i.length,
      });
    });
    return mlist;
  }

  handleFinally(arr: any) {
    const tempArr = this.occurNumber(arr);
    let finalArr = [] as any;
    tempArr.forEach((item: any, idx: number) => {
      const sArr = [] as any;
      if (item.num > 1) {
        const sameItems = arr.filter((i: any, index: number) => {
          return i.sid == item.name;
        });
        sameItems.forEach((detailItem: any) => {
          sArr.push(...detailItem.s);
          console.log(sArr, "sArr");
        });
        finalArr = {
          question_type: sameItems[0].question_type,
          s: sArr,
          sid: sameItems[0].sid,
        };
        this.scaleRadioArrMore.splice(idx, item.num, finalArr);
      }
    });
  }

  //点击取消按钮触发函数
  click_cancel() {
    this.$router.go(-1);
  }
  //点击确定按钮触发函数
  scaleRecordAdd() {
    const myForm = this.$refs["ruleForm"] as Form;
    myForm.validate((valid) => {
      if (valid) {
        //患者信息
        const patient = this.patientInfo;
        const form = this.form_data;
        const scaleChoiceArr = [];
        if (this.scaleRadioArr.length) {
          scaleChoiceArr.push(...this.scaleRadioArr);
        }
        if (this.scaleRadioArrMore.length) {
          this.handleFinally(this.scaleRadioArrMore);
          scaleChoiceArr.push(...this.scaleRadioArrMore);
        }
        if (this.moduleId == "222") {
          const tempArr = this.handleImageScale();
          tempArr.forEach((item: any, idx: number) => {
            if (item.s.length == 1 && item.s[0].score == undefined) {
              tempArr.splice(idx, 1);
            }
          });
          scaleChoiceArr.push(...tempArr);
          console.log(tempArr, "========蒙特利尔scaleAnswer");
        }
        const medicine = JSON.stringify(this.use_medicine_list);
        //药物名称及用量
        const param = {
          database_id: this.databaseId,
          patient_id: patient.user_id,
          patient_name: patient.user_name,
          patient_edu: patient.user_education,
          patient_relation: form.relationValue,
          state_before_measure: form.scaleStatusRadio,
          device_info:
            form.scaleStatusRadio == "需要" ? form.deviceStatusRadio : null,
          medication_info:
            form.scaleStatusRadio == "需要" ? form.medicineStatusRadio : null,
          evaluate_name: form.assessName,
          evaluate_date: this.formatTimeHMS(form.assessDate),
          need_last_medication: form.lastMedicaineRadio.length
            ? form.lastMedicaineRadio
            : null,
          last_medication_time: this.lastMedicineDate
            ? this.formatTimeHMS(this.lastMedicineDate)
            : null,
          last_medication: this.use_medicine_list.length ? medicine : null, //药物名称及用量  ----非必填
          question_result: {
            module: this.moduleId,
            //答案详情
            score_detail: scaleChoiceArr.length
              ? JSON.stringify(scaleChoiceArr)
              : null,
          },
        };
        ApiAddScaleRecord(param)
          .then((res) => {
            if (res.success) {
              this.$router.push({
                name: "evaluationlist",
                params: {
                  moduleId: this.moduleId,
                  id: this.patientInfo.user_id,
                  databaseId: this.databaseId,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }
  //格式化时间
  formatTime(time: any) {
    const date = new Date(time);
    return `${date.getFullYear()}-${this.formatDate(
      date.getMonth() + 1
    )}-${this.formatDate(date.getDate())} 00:00:00`;
  }
  //格式化时间
  formatTimeHMS(time: any) {
    const date = new Date(time);
    return `${date.getFullYear()}-${this.formatDate(
      date.getMonth() + 1
    )}-${this.formatDate(date.getDate())} ${this.formatDate(
      date.getHours()
    )}:${this.formatDate(date.getMinutes())}:${this.formatDate(
      date.getSeconds()
    )}`;
  }
  formatDate(date: any) {
    const str = date.toString();
    if (str.length === 2) {
      return str;
    } else {
      return "0" + str;
    }
  }
  //选择药物表格数据变化时触发的函数
  handleSelectionChange(row: any) {
    //用于存储对话框选中的表格行
    this.multipleSelection = row;
  }
  rowClass({ row, rowIndex }: any) {
    if (this.multipleSelection.includes(row as never)) {
      return "slecleRowColor ";
    }
  }
  //点击对话框取消按钮触发的函数
  closeDialog() {
    this.addMedicineDialog = false;
    const myTable: any = this.$refs["multipleTable"];
    myTable.clearSelection();
  }
  //对话框点击添加触发的函数
  insertMedicine() {
    //把对话框表格选中行赋值给所服药物的表格
    this.use_medicine_list = this.multipleSelection.map((i: any) => {
      return {
        name: i.name,
        dosage: "",
      };
    }) as any;
    //关闭对话框
    this.addMedicineDialog = false;
  }
  //点击表格中的每一项中的删除按钮触发的函数
  deleteMedicine(row: any) {
    this.use_medicine_list = this.use_medicine_list.filter((item) => {
      return item != row;
    });
  }
  //点击表格某一行触发函数
  clickRow(row: any, column: any, event: any) {
    const myTable: any = this.$refs["multipleTable"];
    myTable.toggleRowSelection(row);
  }
  //点击选择药物按钮触发的函数
  chooseMedicine() {
    this.addMedicineDialog = true;
    const myTable: any = this.$refs["multipleTable"];
    //如果是第一次代开对话框，则没有对话框里的表格实例
    if (myTable) {
      //先把对话框内表格选中框清零
      myTable.clearSelection();
      if (this.use_medicine_list.length != 0) {
        const table = this.use_medicine_list.map((item: any) => {
          return {
            name: item.name,
          };
        });
        for (const i of table) {
          const index: any = this.findIndex(this.medicineData, i);
          myTable.toggleRowSelection(myTable.data[index]);
        }
      }
    }
  }
  findIndex(arr: any, item: any) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === item.name) {
        return i;
      }
    }
  }
  handleRadioScore(item: any, value: string) {
    // 返回对应选项的score值;
    const score = {
      score: "",
      index: -1,
    };

    item.forEach((item: any, index: number) => {
      // question_type 为 103 104时单独处理;
      if (item.items && item.items.length) {
        item.items.forEach((detail: any, detailIndex: number) => {
          if (detail.choice_name == value) {
            score.score = detail.score;
            score.index = detailIndex;
          }
        });
        return;
      }
      if (item.choice_name == value) {
        score.score = item.score;
        score.index = index;
      }
    });
    return score;
  }

  handleRepeatArr(arr: any, value: any) {
    let flag = -1;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].sid == value) {
        flag = i;
      }
    }
    return flag;
  }
  // ...More系列全部都是和103 104相关的;
  handleRepeatArrMore(arr: any, value: any, sid: any) {
    let flag = -1;
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].s.length; j++) {
        if (arr[i].s[j].choice_index[0] == value && arr[i].sid == sid) {
          flag = i;
        }
      }
    }
    return flag;
  }
  // question_type 103 104时 提交答案json封装;
  handleAnswerFormatMore(evt: any, val: any) {
    const splitArr = evt.split("_");
    const chooseScore = splitArr[0]; // 第一个是具体的分值 第二个是第几个小项的index;
    const chooseIndex = splitArr[1];
    const real_score = splitArr[2];
    const score = this.handleRadioScore(val.choice_item, chooseScore);
    // 若原先就没有答案 直接push;
    if (this.scaleRadioArrMore.length == 0) {
      const s = [];
      s.push({
        choice_index: [Number(chooseIndex), Number(chooseScore)],
        score: real_score,
      });
      this.scaleRadioArrMore.push({
        question_type: val.question_type,
        s: s,
        sid: val.sid,
      });
      // 拼成的答案数组; 103 104;
      // console.log(this.scaleRadioArrMore, "scaleRadioArrMore============add");
      return;
    }
    const isRepeat = this.handleRepeatArrMore(
      this.scaleRadioArrMore,
      chooseIndex,
      val.sid
    );
    // console.log(isRepeat, "isRepeat");
    // 若已经选择答案 则比较是否是更改的选项;
    if (isRepeat == -1) {
      console.log("没重复的");
      const s = [];
      s.push({
        choice_index: [Number(chooseIndex), Number(chooseScore)],
        score: chooseScore,
      });
      this.scaleRadioArrMore.push({
        question_type: val.question_type,
        s: s,
        sid: val.sid,
      });
    } else {
      const s = [];
      s.push({
        choice_index: [Number(chooseIndex), Number(chooseScore)],
        score: chooseScore,
      });
      const newArrMore = {
        question_type: val.question_type,
        s: s,
        sid: val.sid,
      };
      this.scaleRadioArrMore.splice(isRepeat, 1, newArrMore);
    }
    // 拼成的答案数组; 103 104;
    // console.log(this.scaleRadioArrMore, "103/104题型");
  }
  // question_type 102时 提交答案json封装;
  handleAnswerFormat(evt: any, val: any) {
    const score = this.handleRadioScore(val.choice_item, val.chooseScore);
    // 若原先就没有答案 直接push;
    if (this.scaleRadioArr.length == 0) {
      const s = [];
      s.push({
        choice_index: [score.index],
        choice_name: val.chooseScore,
        score: score.score,
      });
      this.scaleRadioArr.push({
        question_type: val.question_type,
        s: s,
        sid: val.sid,
      });
      // 拼成的答案数组;
      // console.log(this.scaleRadioArr, "102题型");
      return;
    }
    const isRepeat = this.handleRepeatArr(this.scaleRadioArr, val.sid);
    // console.log(isRepeat, "isRepeat");
    // 若已经选择答案 则比较是否是更改的选项;
    if (isRepeat == -1) {
      console.log("没重复的");
      const s = [];
      s.push({
        choice_index: [score.index],
        choice_name: val.chooseScore,
        score: score.score,
      });
      this.scaleRadioArr.push({
        question_type: val.question_type,
        s: s,
        sid: val.sid,
      });
    } else {
      const s = [];
      s.push({
        choice_index: [score.index],
        choice_name: val.chooseScore,
        score: score.score,
      });
      const newArr = {
        question_type: val.question_type,
        s: s,
        sid: val.sid,
      };
      this.scaleRadioArr.splice(isRepeat, 1, newArr);
    }
    // 拼成的答案数组;
    // console.log(this.scaleRadioArr, "scaleRadioArr============add");
  }

  chooseRadioEvent(evt: any, val: any) {
    // 子组件传过来的选项;
    if (val.question_type == 103 || val.question_type == 104) {
      this.handleAnswerFormatMore(evt, val);
    } else {
      this.handleAnswerFormat(evt, val);
    }
    // 答案格式;
    //  sid: {
    //   question_type: item.question_type,
    //   s:[
    //     choice_index: [0],  // 按类型判断index;
    //     choice_name: item.chooseScore,
    //     score: score
    //   ],
    //  "s": [{"choice_index": [1, 1], "score": 1},  // 2级目录多选
    //   sid: xx
    // }
  }
  // 蒙特利尔问卷单独处理;
  imageScaleEvent(val: any) {
    const scaleAnswer = [] as any;
    scaleAnswer.push(
      {
        question_type: 105,
        s: [
          {
            choice_index: [0, val.input1_1],
            score: val.input1_1,
          },
        ],
        sid: 2,
      },
      {
        question_type: 105,
        s: [
          {
            choice_index: [0, val.input1_2],
            score: val.input1_2,
          },
        ],
        sid: 3,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input1_3],
            score: val.input1_3,
          },
          {
            choice_index: [1, val.input1_4],
            score: val.input1_4,
          },
          {
            choice_index: [2, val.input1_5],
            score: val.input1_5,
          },
        ],
        sid: 4,
      },
      {
        question_type: 105,
        s: [
          {
            choice_index: [0, val.input2_1],
            score: val.input2_1,
          },
          {
            choice_index: [1, val.input2_2],
            score: val.input2_2,
          },
          {
            choice_index: [2, val.input2_3],
            score: val.input2_3,
          },
        ],
        sid: 5,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, 0],
            score: -1,
          },
        ],
        sid: 6,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input4_1],
            score: val.input4_1,
          },
          {
            choice_index: [0, val.input4_2],
            score: val.input4_2,
          },
        ],
        sid: 7,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input5_1],
            score: val.input5_1,
          },
        ],
        sid: 8,
      },
      {
        question_type: 102,
        s: [
          {
            choice_index: [val.input6_1],
            score: val.input6_1,
          },
          {
            choice_index: [val.input6_2],
            score: val.input6_1,
          },
          {
            choice_index: [val.input6_3],
            score: val.input6_1,
          },
        ],
        sid: 9,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input7_1],
            score: val.input7_1,
          },
          {
            choice_index: [0, val.input7_2],
            score: val.input7_2,
          },
        ],
        sid: 10,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input8_1],
            score: val.input8_1,
          },
        ],
        sid: 11,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input9_1],
            score: val.input9_1,
          },
          {
            choice_index: [0, val.input9_2],
            score: val.input9_2,
          },
        ],
        sid: 12,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input10_1],
            score: val.input10_1,
          },
          {
            choice_index: [1, val.input10_2],
            score: val.input10_2,
          },
          {
            choice_index: [2, val.input10_3],
            score: val.input10_3,
          },
          {
            choice_index: [3, val.input10_4],
            score: val.input10_4,
          },
          {
            choice_index: [3, val.input10_5],
            score: val.input10_5,
          },
        ],
        sid: 13,
      },
      {
        question_type: 104,
        s: [
          {
            choice_index: [0, val.input11_1],
            score: val.input11_1,
          },
          {
            choice_index: [1, val.input11_2],
            score: val.input11_2,
          },
          {
            choice_index: [2, val.input11_3],
            score: val.input11_3,
          },
          {
            choice_index: [3, val.input11_4],
            score: val.input11_4,
          },
          {
            choice_index: [4, val.input11_5],
            score: val.input11_5,
          },
          {
            choice_index: [5, val.input11_6],
            score: val.input11_6,
          },
        ],
        sid: 14,
      }
    );
    this.mengAnswer = scaleAnswer;
  }
  handleImageScale() {
    const tempArr = this.mengAnswer as any;
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].s.length == 1) {
        if (!tempArr[i].s[0].score) tempArr.splice(i, 1);
      } else if (tempArr[i].s.length > 1) {
        for (let j = 0; j < tempArr[i].s.length; j++) {
          if (!tempArr[i].s[j].score) {
            tempArr[i].s.splice(j, 1);
          }
        }
      }
    }
    return tempArr;
  }
}
