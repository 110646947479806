















































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ApiGetQuestionList } from "@/apis";
import { scaleDesc } from "@/assets/js/scaleDesc.ts";
import { ScaleImageResult } from "@/types";
@Component({
  components: {},
})
export default class ScaleComponent extends Vue {
  radio = "";
  input = "";
  questionLists: any = [];
  scaleDescs = scaleDesc;
  imageScale: any = {} as ScaleImageResult;

  @Prop() moduleId!: string;
  created() {
    this.getQuestionList();
  }
  mounted() {
    // ApiGetQuestionList
  }
  getQuestionList() {
    ApiGetQuestionList({ module: this.moduleId }).then((res) => {
      if (res.success) {
        res.data.forEach((item: any) => {
          if (item.choice_item && typeof item.choice_item === "string") {
            item.choice_item = JSON.parse(item.choice_item);
            item.questionArr = item.question.split("\r\n");
          }
        });
        this.questionLists = res.data;
        // console.log(this.questionLists,'这里是questionLists');
      }
    });
  }
  scoreRadioChange(evt: any, item: object) {
    // console.log(evt, item, "scoreRadioChange===========");
    this.$emit("chooseRadioEvent", evt, item);
  }
  imageScaleChange(value: any) {
    // console.log(this.imageScale);
    this.$emit("imageScaleEvent", this.imageScale);
  }
  getScore(i: any, e: string) {
    const str_length = i.length;
    this.imageScale[e] = i.replace(/[^01]/g, "");
    if (this.imageScale[e].length != 0) {
      const last_str = i.slice(str_length - 1, str_length);
      if (last_str == 0 || last_str == 1) this.imageScale[e] = last_str;
    }
  }
}
