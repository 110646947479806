export const dosage = [
  {
    value: "1/8",
    label: "1/8",
  },
  {
    value: "1/4",
    label: "1/4",
  },
  {
    value: "1/2",
    label: "1/2",
  },
  {
    value: "3/4",
    label: "3/4",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "1+1/4",
    label: "1+1/4",
  },
  {
    value: "1+1/2",
    label: "1+1/2",
  },
  {
    value: "1+3/4",
    label: "1+3/4",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "3+1/2",
    label: "3+1/2",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
];
